





































































































import { DisplayedDevorOrder } from '@/interfaces/display'
import { sortOrder, TicketOrder, TicketItemGroup } from '@/utils/sort'
import {
  formatItemTag,
  secondsIntervalFormatted,
} from '@/utils/formatting-routines'
import Vue, { PropType } from 'vue'

const kdsFilter = ({ showOnKDS }: { showOnKDS: boolean }) => showOnKDS

export default Vue.extend({
  props: {
    displayedDevorOrder: {
      type: Object as PropType<DisplayedDevorOrder>,
      required: true,
    },
    timerDuration: {
      type: Number,
      required: true,
    },
    showGroupingTags: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ticketOrder(): TicketOrder {
      const { ticketItemGroups, notes } = sortOrder(this.displayedDevorOrder)
      // Filter out items that shouldn't be displayed on the KDS
      return {
        notes: notes.filter(kdsFilter),
        ticketItemGroups: ticketItemGroups.map(({ groupingTag, content }) => ({
          content: content.filter(kdsFilter),
          groupingTag,
        })),
      }
    },
    groupedItemsFiltered(): TicketItemGroup[] {
      return this.ticketOrder.ticketItemGroups.filter(
        (group) => group.content.length > 0
      )
    },
    timerRemaining(): number | null {
      if (!this.displayedDevorOrder.timeElapsedSinceAcknowledgment) return 0
      return (
        this.timerDuration -
        (this.displayedDevorOrder.timeElapsedSinceAcknowledgment %
          this.timerDuration)
      )
    },
    timerRemainingFormatted(): string {
      if (!this.timerRemaining) return ''
      return this.secondsIntervalFormatted(Math.round(this.timerRemaining))
    },
  },
  methods: {
    formatItemTag,
    secondsIntervalFormatted,
  },
})
