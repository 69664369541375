































































import Vue from 'vue'
import { DisplayedDevorOrder } from '@/interfaces/display'
import { DevorOrderPreparationState } from '@/api-client/model/devor-order-preparation-state'
import { DevorOrderAcceptationState } from '@/api-client/model/devor-order-acceptation-state'
import OrderDisplayCell from '@/components/OrderDisplayCell.vue'
import Logo from '@/components/Logo.vue'
import LogoVariant from '@/utils/enum-logo-variant'
import { NavigationGuard } from 'vue-router'

const checkRoute: NavigationGuard = (to, from, next) => {
  const { id, name } = to.query
  if (!(id && typeof id === 'string' && name && typeof name === 'string')) {
    // Invalid route, deny navigation
    next(false)
    return
  }
  next()
}

export default Vue.extend({
  components: { OrderDisplayCell, Logo },
  beforeRouteEnter: checkRoute,
  beforeRouteUpdate(to, from, next) {
    checkRoute(to, from, next)
    // Next was already called by `checkRoute`
    this.checkFirebaseLogin()
  },
  data: () => ({
    ordersInKitchen: Array<DisplayedDevorOrder>(),
    intervalTimer: null as null | number,
    firebaseUnsubscribe: null as null | (() => void),
    currentDate: new Date(),
  }),
  computed: {
    logoVariant: (): LogoVariant => LogoVariant.BEIGE,
    timeFormatted(): string {
      return this.currentDate.toLocaleTimeString('fr-fr', {
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    kitchenID(): string {
      const { id } = this.$route.query
      if (!(id && typeof id === 'string')) {
        // Invalid route, shouldn't happen thanks to Nav Guards
        return ''
      }
      return id
    },
    kitchenName(): string {
      const { name } = this.$route.query
      if (!(name && typeof name === 'string')) {
        // Invalid route, shouldn't happen thanks to Nav Guards
        return ''
      }
      return name
    },
    zoomPercentage(): number | null {
      const { zoom: zoomString } = this.$route.query
      if (zoomString && typeof zoomString === 'string') {
        const zoomParsed = parseInt(zoomString)
        if (zoomParsed && zoomParsed > 0) return zoomParsed
      }
      return null
    },
    zoomStyle(): { zoom?: string } {
      if (!this.zoomPercentage) return {}
      return {
        zoom: this.zoomPercentage + '%',
      }
    },
    timerTimeSeconds(): number {
      let timerSeconds = 60 // Default to one minute
      const { timer: timerSecondsString } = this.$route.query
      if (timerSecondsString && typeof timerSecondsString === 'string') {
        const timerSecondsParsed = parseInt(timerSecondsString)
        if (timerSecondsParsed && timerSecondsParsed > 0)
          timerSeconds = timerSecondsParsed
      }
      return timerSeconds
    },
    ordersInKitchenFiltered(): DisplayedDevorOrder[] {
      return this.ordersInKitchen.filter(
        (order) =>
          // Order hasn't been acknowledge...
          !order.timeElapsedSinceAcknowledgment ||
          // ...or it has been acknowledged for less than `timerTimeSeconds`
          order.timeElapsedSinceAcknowledgment < this.timerTimeSeconds
      )
    },
  },
  created() {
    this.checkFirebaseLogin().then(() => {
      this.firebaseUnsubscribe = this.$firebase
        .firestore()
        .collection(`kitchens/${this.kitchenID}/orders`)
        .where(
          'current_state.acceptation',
          '==',
          DevorOrderAcceptationState.Accepted.valueOf()
        )
        .where(
          'current_state.preparation',
          '==',
          DevorOrderPreparationState.InKitchen.valueOf()
        )
        .onSnapshot((querySnapshot) => {
          // Push all updates to a new array
          // that will overwrite our current orders.
          const newOrders = Array<DisplayedDevorOrder>()
          querySnapshot.forEach((doc) => {
            doc
            newOrders.push({
              firestoreID: doc.id,
              ...doc.data(),
            } as DisplayedDevorOrder)
          })

          // Sort orders
          function cmpOrdersPlacedAt(
            a: DisplayedDevorOrder,
            b: DisplayedDevorOrder
          ): number {
            return b.placed_at.seconds - a.placed_at.seconds
          }

          this.updateOrders(newOrders.sort(cmpOrdersPlacedAt))
        })

      this.intervalTimer = setInterval(() => {
        // This method is run every second
        this.currentDate = new Date()
        this.updateOrders()
      }, 1_000)
    })
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer ?? undefined)
    if (this.firebaseUnsubscribe) this.firebaseUnsubscribe()
  },
  methods: {
    /**
     * This method retrieves the hash from the route, and if it is present, it uses it
     * to log-in with Firebase, and then removes it.
     */
    async checkFirebaseLogin() {
      const hash = this.$route.hash
      const matched = hash.match(/^#(.*)/)
      if (matched?.length === 2) {
        const firebaseToken = matched[1]
        // Login to Firebase using token
        try {
          await this.$firebase.auth().signInWithCustomToken(firebaseToken)
        } catch (error) {
          this.$sentry.captureException(error)
          this.$router.push('/')
          return
        }
        // Log-in successful, remove token from URL
        this.$router.replace({
          ...this.$route,
          hash: undefined,
          name: this.$route.name ?? undefined,
        })
        return
      }
    },
    // Display methods
    computeDisplayAttributes(
      order: DisplayedDevorOrder,
      dateNow: Date
    ): DisplayedDevorOrder {
      const timeElapsedSinceAcknowledgment = order.acknowledged_at
        ? (dateNow.getTime() - order.acknowledged_at.toMillis()) / 1_000
        : undefined
      return {
        ...order,
        timeElapsedSinceAcknowledgment,
        isSeen: true,
      }
    },
    updateOrders(newOrdersInKitchen?: Array<DisplayedDevorOrder>) {
      newOrdersInKitchen = newOrdersInKitchen ?? this.ordersInKitchen

      const dateNow = new Date()
      this.ordersInKitchen = newOrdersInKitchen.map((order) =>
        this.computeDisplayAttributes(order, dateNow)
      )
    },
  },
})
